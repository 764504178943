.card-investment-item {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 24px;
  // padding-top: 48px;

  .card-investment-content {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .left-content {
      flex: 2;

      .title {
        font-weight: 600;
        padding-bottom: 24px;
        font-size: 3.2rem;
        line-height: 4.2rem;
      }

      span {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .image-group {

      align-self: flex-start;
      flex: 1;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .right-content {
      margin-top: 24px;

      .hiden-button {}

      button {
        background: transparent;
        color: #25aae1;
        font-size: 1.6rem;

        &:hover {
          cursor: pointer;
        }
      }

      .circle {
        background: transparent;
        color:  #25457C;
        background: #ffffff;
        border: 1px solid  #25457C;
        border-radius: 30px;
        padding: 4px 22px;
        font-size: 18px;
        transition: ease 0.4s;

        svg {
          margin-left: 16px;
        }

        &:hover {
          background:  #25457C;
          color: #ffffff;
        }
      }
    }
  }
}