.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    .inner {
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}