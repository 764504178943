.news-wrapper {
  width: 100%;
  height: auto;

  .inner {
    width: 100%;
    height: auto;
    padding: 0 140px 100px;

    .news-header {
      width: 100%;
      min-height: 50px;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;

      .nav-news {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .all-category {
          display: flex;
          align-items: center;
          svg {
            font-size: 2rem;
          }

          span {
            margin-left: 8px;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.6rem;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .nav-list {
          display: flex;
          column-gap: 16px;
        }
      }
    }

    .news-content {
      display: flex;
      column-gap: 50px;
      margin-top: 48px;

      .top-news-left {
        flex: 7;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .new-news-wrapper {
          h1 {
            font-weight: 700;
            font-size: 3rem;
            line-height: 4rem;
          }

          .new-news-group {
            // display: flex;
            // flex-direction: column;
            row-gap: 16px;
            margin-top: 12px;
            justify-content: space-between;

            .full-news {
              display: flex;
              column-gap: 16px;

              img {
                width: 600px;
                height: 320px;
                border-radius: 8px;
              }

              .news-details {
                display: flex;
                flex-direction: column;

                h3 {
                  flex: 2;
                  font-weight: 600;
                  font-size: 2.2rem;
                  line-height: 3rem;
                  transition: 0.3s;

                  &:hover {
                    cursor: pointer;
                    color: #094b72;
                  }
                }

                .desc-news {
                  margin-top: 14px;
                  display: -webkit-box;
                  flex: 6;
                  -webkit-line-clamp: 8;
                  visibility: visible;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  overflow: hidden;

                  .show-more {
                    color: rgb(104, 172, 2);
                  }
                }

                .category-time-group {
                  flex: 2;
                  display: flex;
                  align-items: flex-end;
                  padding-bottom: 16px;
                  color: #072d4b;
                  opacity: 0.4;

                  .time {
                    margin-left: 12px;
                  }
                }
              }
            }

            .small-news {
              display: flex;
              .list-news {
                display: flex;
                flex-wrap: wrap;
                gap: 32px;

                // .card-news-item {
                //     .card-news-left {
                //         display: flex;
                //     }
                // }
              }
            }
          }
        }

        .event-outstanding {
          // flex: 8;
          display: flex;
          flex-direction: column;

          .title-category {
            display: flex;
            justify-content: space-between;
            margin-top: 48px;
            h1 {
              font-weight: 700;
              font-size: 3rem;
              line-height: 4rem;
            }
            .read-more {
              display: flex;
              align-items: center;
              .read-more-btn {
                background: #25457C;
                border-radius: 25px;
                padding: 8px 24px;
                color: #ffffff;
                transition: 0.3s;
                // margin-right: 15px;

                &:hover {
                  cursor: pointer;
                  opacity: 0.7;
                }
              }
            }
          }

          .event-group {
            flex: 8;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .group-3 {
              width: 100%;

              .list-event {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                justify-content: space-between;

                .event-item {
                  width: 32%;
                  min-height: 400px;
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

                  .event-image {
                    width: 100%;
                    height: 38%;
                    background-color: #ccc;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 8px 8px 0px 0px;
                    }
                  }

                  .event-details {
                    flex: 2;
                    padding: 16px 20px 0 20px;
                    display: flex;
                    flex-direction: column;
                    .time {
                      flex: 1;
                      font-weight: 400;
                      font-size: 1.2rem;
                      line-height: 1rem;
                      color: #545454;
                    }

                    h3 {
                      flex: 2;
                      font-weight: 600;
                      font-size: 2.2rem;
                      line-height: 3rem;
                      color: #003865;

                      &:hover {
                        cursor: pointer;
                      }
                    }

                    .desc {
                      margin-top: 4px;
                      flex: 3;
                      font-weight: 400;
                      font-size: 1.2rem;
                      line-height: 1.8rem;
                    }
                  }

                  .read-more {
                    text-align: center;
                    margin-bottom: 16px;

                    button {
                      background: #25457C;
                      border-radius: 8px;
                      padding: 8px 24px;
                      color: #ffffff;
                      transition: 0.3s;

                      &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                      }
                    }
                  }
                }
              }
            }

            .multi-event {
              margin-top: 24px;

              .list-event-small {
                display: flex;
                flex-wrap: wrap;
                row-gap: 25px;
                justify-content: space-between;

                .card-story-item {
                  width: 31%;
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }

      .top-news-right {
        flex: 3;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        // margin-left: 100px;

        .advertise {
          display: flex;
          // justify-content: center;
          img {
            max-height: 665px;
            width: 100%;
          }
        }

        .most-watch {
          display: flex;
          flex-direction: column;
          // width: 300px;

          h2 {
            font-weight: 700;
            font-size: 3rem;
            line-height: 4rem;
          }

          .list-news {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            .news-item {
              display: flex;
              flex-direction: column;
              box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 5px;
              border-radius: 3px;
              // row-gap: 10px;
              padding: 16px;
              .news-title:hover {
                cursor: pointer;
              }

              .bottom {
                font-size: 1.2rem;
                line-height: 1.8rem;
                color: #072d4b;
                opacity: 0.4;
              }
            }
          }
        }
      }
    }

    .success-story-content {
      width: 100%;

      .title {
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;
        justify-content: space-between;
        h2 {
          font-weight: 700;
          font-size: 3rem;
          line-height: 4rem;
        }
        .read-more {
          display: flex;
          align-items: center;
          // justify-content: center;
          .read-more-btn {
            background: #25457C;
            border-radius: 25px;
            padding: 8px 24px;
            color: #ffffff;
            transition: 0.3s;
            // margin-right: 10px;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }

        .card-story-item {
          width: 31%;
          display: flex;
          flex-direction: column;
        }
      }

      .story-group {
        width: 100%;
        margin-top: 24px;

        .list-story {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
        }
      }
    }

    .investment-connection {
      width: 100%;

      .title {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        h2 {
          font-weight: 700;
          font-size: 3rem;
          line-height: 4rem;
        }
        .read-more {
          display: flex;
          align-items: center;
          .read-more-btn {
            background: #25457C;
            border-radius: 25px;
            padding: 8px 24px;
            color: #ffffff;
            transition: 0.3s;
            margin-right: 18px;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }
      }

      .investment-group {
        width: 100%;
        margin-top: 24px;

        .list-card {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
        }
      }
    }
  }
}
