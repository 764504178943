.competition-wrapper#competition-activity {
  width: 100%;

  .list-competition {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;


    .competition-item {
      display: flex;
      background: rgba(250, 250, 250, 0.8);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
      border-radius: 12px;

      .image-group {
        flex-shrink: 0;

        img {
          border-radius: 14px 0 0 14px;
          width: 300px;
          // width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .abc {
        padding: 16px 24px 16px;


        .competition-details {
          display: flex;
          flex-direction: column;

          h3 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 1;
            font-weight: 500;
            font-size: 2rem;
            // line-height: 24px;
            color: black;
            margin-bottom: 16px;
            // margin-bottom: 16px;
          }

          .date-time {
            font-weight: 400;
            font-size: 16px;
            color: black;
            margin-bottom: 8px;

            span {
              color: #838383;
            }
          }

          .status {
            // margin-bottom: 16px;
            margin-top: auto;

            .status-btn.end {
              background: #ddd;
              border-radius: 12px;
              width: -moz-fit-content;
              width: fit-content;
              padding: 8px 24px;
              border: 1px solid #ddd;
              color: #989898;
              opacity: 0.7;
            }

            .status-btn.in-progress {
              padding: 8px 24px;
              background: #25457C;
              border-radius: 12px;
              border: 1px solid #ddd;
              color: #fff;
            }

            .status-btn.upcoming {
              border-radius: 30px;
              background:  #25457C;
              padding: 8px 24px;
              // width: 120px;
              color: white;
            }

            p {
              // color: #3e4345;
            }

            span {
              // color: #39b54a;
            }
          }

          .program {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 16px;
            color: black;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;

            span {
              color: #838383;
            }
          }
        }

        .action-submit {
          margin-top: auto;

          .btnEnd {
            font-size: 16px;
            padding: 8px 24px;
            border-radius: 30px;
            height: auto;
            color: red;
            border: 1px solid red;
            transition: all .3s;
            opacity: 0.4;
            user-select: none;

            &:hover {
              cursor: unset;
            }
          }

          .btnRes {
            font-size: 16px;
            padding: 8px 24px;
            border-radius: 30px;
            height: auto;
            color:  #25457C;
            border: 1px solid  #25457C;
            transition: all .3s;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
              background:  #25457C;
              color: white;
            }
          }

        }
      }
    }
  }
}