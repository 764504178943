.wrapper {
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    // justify-content: center;
    // align-items: center;

    .inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}