.advise-wrapper#advise-activities {
  width: 100%;
  height: auto;
  margin: 24px 0 24px 0;

  .inner {
    width: 100%;

    .content-main-advise {
      width: 100%;

      .search-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-group {
          display: flex;
          flex-direction: column;
          min-width: 40%;

          label {
            color: #0d90c2;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 1.8rem;
            margin-bottom: 12px;
          }
        }

        .search-btn {
          margin-top: 24px;

          .search-group {
            min-width: 300px;
            // border: 1px solid #25aae1;
            border-radius: 4px;
            padding: 24px 16px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
          }

          button {
            min-width: 120px;
            height: 35px;
            background: #ffffff;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            color:  #25457C;
            transition: 0.3s;
            border: 1px solid  #25457C;
            border-radius: 10px;

            &:hover {
              background-color: rgba(204, 204, 204, 0.3);
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }
      }

      .consultants-wrap {
        width: 100%;
        margin-top: 10px;

        .consultants-list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 32px;

          .consultants-item {
            display: flex;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            flex-direction: column;

            .consultants-image {
              width: 100%;
              height: 300px;
              border-radius: 12px 12px 0 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px 12px 0 0;
              }
            }

            .consultants-details {
              padding: 16px 24px 32px 24px;
              width: 100%;
              display: flex;
              flex-direction: column;

              .consultants-details__left {
                flex: 1 1;
                display: flex;
                flex-direction: column;

                .consultants-details__name {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 1.8rem;
                  margin-bottom: 16px;
                }

                .consultants-details-grey {
                  font-weight: 400;
                  margin-bottom: 12px;
                  font-size: 16px;
                  line-height: 1.4rem;
                  color: black;
                }
              }

              .consultants-details__right {
                margin-top: 8px;
                display: flex;
                gap: 16px;


                .consultants-details__action {
                  border: 1px solid  #25457C;
                  border-radius: 30px;
                  height: 42px;
                  padding: 10px 30px;
                  display: flex;
                  column-gap: 8px;
                  align-items: center;
                  transition: 0.3s;
                  background:  #25457C;
                  color: #ffffff;

                  svg {
                    font-size: 2rem;
                    color: #ffffff;
                  }

                  &:hover {
                    cursor: pointer;
                    background-color: #ffffff;
                    color:  #25457C;
                    svg {
                      font-size: 2rem;
                      color:  #25457C;
                    }
                  }
                }

                .consultants-details__action1 {
                  border: 1px solid  #25457C;
                  border-radius: 30px;
                  padding: 10px 30px;
                  display: flex;
                  column-gap: 8px;
                  height: 42px;
                  align-items: center;
                  transition: 0.3s;
                  color:  #25457C;
                  background-color: #ffffff;
                  svg {
                    font-size: 2rem;
                    color:  #25457C;
                  }

                  &:hover {
                    cursor: pointer;
                    background-color: #25457C;
                    color: #ffffff;
                    svg {
                      font-size: 2rem;
                      color:  #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}