.card-top-project {
  width: 100%;
  height: 510px;
  padding: 40px 140px;
  border-bottom: 1px solid #ddd;

  .card-top-left-wrap {
    width: 100%;

    .image-group {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      width: 100%;

      .big-image {
        width: 100%;
        height: 358px;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .small-image {
        display: flex;
        column-gap: 8px;

        img {
          width: 60px;
          height: 39px;
          object-fit: contain;

          &:hover {
            cursor: pointer;
          }
        }

        img.small-active {
          border: 1px solid #ff0000;
        }
      }
    }
  }

  .card-top-right-wrap {
    width: 100%;

    .card-top-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      h3 {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 2.4rem;
      }

      .project-des {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #ababab;
      }

      .company-group {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .company-image {
          width: 60px;
          height: 60px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .cardTop-company-detail {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .company-detail-bottom {
            display: flex;
            align-items: center;
            column-gap: 8px;

            span {
              color: #dddddd;
            }
          }
        }
      }

      .investment-call {
        display: flex;
        column-gap: 40px;

        .calling,
        .called {
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          label {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
          }

          span {
            font-weight: 600;
            font-size: 2rem;
            line-height: 2rem;
            color: #39b54a;
          }
        }
      }

      .investment-action {
        display: flex;
        column-gap: 24px;
        margin-top: 14px;

        .primary-button {
          padding: 12px 48px;
          background:  #25457C;
          border-radius: 30px;
          color: var(--white);
          font-size: 1.6rem;

          &:hover {
            cursor: pointer;
          }
        }

        .follow-btn {
          background: #fff;
          border: 1px solid #828282;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 12px;
          padding: 12px 18px;
          font-size: 1.6rem;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
