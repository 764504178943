.introduce-warpper {
  padding: 0 0 48px 0;
  width: 100%;

  .introduce-inner {
    padding: 80px 140px;
    background-color: #F9FCFF;
  }

  .investment-funds {
    // margin-top: 48px;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 0 140px;
    // margin: 0 -20px;

    h2 {
      margin-bottom: 16px;
      font-weight: 700;
    }

    .list-investment {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    button {
      margin-top: 16px;
      background: #25457C;
      border: 1px solid #25457C;
      border-radius: 30px;
      padding: 8px 22px;
      font-size: 1.8rem;
      transition: ease 0.4s;
      color: #fff;
      svg {
        margin-left: 10px;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        background:  #fff;
        color: #25457C;

        svg {
          color: #25457C;
        }
      }
    }
  }

  .shopping-mall {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F9FCFF;
    padding: 44px 0;

    .list-shopping {
      // margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 30px;
      padding: 0 110px;
      flex-wrap: wrap;
    }

    button {
      margin-top: 40px;
      background: #25457C;
      border: 1px solid #25457C;
      border-radius: 30px;
      padding: 8px 22px;
      font-size: 1.8rem;
      font-weight: 600;
      color: #fff;
      transition: ease 0.4s;

      svg {
        margin-left: 10px;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        background:  #fff;
        color: #25457C;

        svg {
          color: #25457C;
        }
      }
    }
  }

  .event {
    // margin-top: 24px;
    // width: 100%;
    // padding: 0 140px;

    h2 {
      font-weight: 700;
    }

    .list-event {
      display: flex;
      flex-wrap: wrap;
      // padding-top: 15px;
      column-gap: 32px;
    }

    button {
      margin-top: 40px;
      background: #25457C;
      border: 1px solid #25457C;
      border-radius: 30px;
      padding: 8px 22px;
      font-size: 1.8rem;
      font-weight: 600;
      color: #fff;
      transition: ease 0.4s;

      svg {
        margin-left: 10px;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        background:  #fff;
        color: #25457C;

        svg {
          color: #25457C;
        }
      }
    }
  }

  .rotation {
    .card {
      perspective: 150rem;
      position: relative;
      height: 40rem;
      max-width: 400px;
      margin: 2rem;
      box-shadow: none;
      background: none;
    }

    .card-side {
      height: 35rem;
      border-radius: 15px;
      transition: all 0.8s ease;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      padding: 2rem;
      color: white;
    }

    .card-side.back {
      transform: rotateY(-180deg);
      background-color: #4158d0;
      background-image: linear-gradient(43deg,
          #4158d0 0%,
          #c850c0 46%,
          #ffcc70 100%);
    }

    .card-side.front {
      background-color: #0093e9;
      background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    }

    .card:hover .card-side.front {
      transform: rotateY(180deg);
    }

    .card:hover .card-side.back {
      transform: rotateY(0deg);
    }
  }
}