.shopping-mall-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);

  .image-group {
    border-radius: 12px 12px 0 0;
    height: 226px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .shopping-mall-detail {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 32px 24px;
    // row-gap: 8px;
    font-size: 16px;

    h3 {
      font-size: 20px;
      font-weight: 700;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      visibility: visible;

      &:hover {
        cursor: pointer;
      }
    }

    span {
      color: #828282;
      font-weight: 400;
      // display: -webkit-box;
    }

    .row-group {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }

    .action-btn {
      // text-align: right;
      margin-top: 16px;

      button {
        background:  #25457C;
        border: 1px solid  #25457C;
        border-radius: 30px;
        color: #ffffff;
        padding: 8px 24px;
        font-size: 14px;
      }
    }
  }
}
