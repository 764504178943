.purpose-inner {
  width: 100%;
  position: relative;

  .jet-timeline {
    position: absolute;
    width: 3px;
    height: 100%;
    // background: blueviolet;
    overflow: hidden;
    transform: translateX(-50%);
    left: 50%;
  }

  .timeline-content {
    width: 100%;
    height: 100%;

    .cooperate-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;

      .cooperate-content {
        background-color: #25457C;
        box-shadow: 0px 0px 10px 0px #25457C;
        border-radius: 12px;
        flex-basis: 50%;
        max-width: calc(50% - 40px);
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 30px 44px 30px 40px;
        margin-right: 20px;
        margin-left: 0px;
        color: #ffffff;

        h2 {
          color: #ffffff;
        }

        &::after {
          position: absolute;
          content: "";
          margin-left: calc(-12px / 2);
          margin-right: calc(-12px / 2);
          background-color: #25457C;
          width: 12px;
          height: 12px;
          border-left-width: 0 !important;
          border-bottom-width: 0 !important;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }

      .cooperate-title {
        margin-left: 50px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: #25457C
      }
    }

    .knowledge-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      justify-content: flex-end;
      margin-top: 20px;

      .knowledge-content {
        background-color: #25457C;
        box-shadow: 0px 0px 10px 0px #25457C;
        border-radius: 12px;
        flex-basis: 50%;
        max-width: calc(50% - 40px);
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 30px 44px 30px 40px;
        margin-left: 20px;
        margin-right: 0;
        color: #ffffff;

        h2 {
          color: #ffffff;
        }

        &::after {
          position: absolute;
          content: "";
          margin-left: calc(-12px / 2);
          margin-right: calc(-12px / 2);
          background-color: #25457C;
          width: 12px;
          height: 12px;
          border-left-width: 0 !important;
          border-bottom-width: 0 !important;
          left: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }

      .knowledge-title {
        margin-right: 51px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: #25457C
      }
    }

    .activity-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;

      .activity-content {
        background-color: #25457C;
        box-shadow: 0px 0px 10px 0px #25457C;
        border-radius: 12px;
        flex-basis: 50%;
        max-width: calc(50% - 40px);
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 30px 44px 30px 40px;
        margin-right: 20px;
        margin-left: 0;
        color: #ffffff;

        h2 {
          color: #ffffff;
        }

        &::after {
          position: absolute;
          content: "";
          margin-left: calc(-12px / 2);
          margin-right: calc(-12px / 2);
          background-color: #25457C;
          width: 12px;
          height: 12px;
          border-left-width: 0 !important;
          border-bottom-width: 0 !important;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }

      .activity-title {
        margin-left: 50px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: #25457C
      }
    }
  }
}