.courses-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0 60px 0;

  .train-post {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
    align-items: center;
    color: rgba(37, 170, 225, 1);

    svg {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .train-courses {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    margin-top: 12px;

    .train-courses__left {


      .train-courses__left-top {
        height: 120px;
        width: 100%;
        background:  #25457C;
        display: flex;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        justify-content: center;
        align-items: center;
        text-align: center;

        span {
          font-weight: 600;
          font-size: 2rem;
          line-height: 2.8rem;
          color: #ffffff;
        }
      }

      .train-courses__left-bottom {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        gap: 32px;

        div {
          padding: 30px 30px;
          padding-top: unset;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 2rem;
          transition: all 0.3s;
          color: #838383;
          user-select: none;
          position: relative;



          &:hover {
            cursor: pointer;
            color:  #25457C;
            transition: all 0.3s;
          }
        }

        div.active {
          color:  #25457C;
          transition: all 0.3s;

          &::after {
            margin-top: 10px;
            content: "";
            background-color:  #25457C;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 100px;
            height: 2.5px;
            position: absolute;
          }
        }
      }
    }

    .train-courses__right {
      width: 100%;

      .train-courses__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 28px 14px;

        .train-courses__item {
          width: 24%;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          overflow: hidden;
          border-radius: 14px 14px 14px 14px;

          &:hover {
            cursor: pointer;
          }

          img {
            width: 100%;
            height: 150px;
            border-radius: 14px 14px 0 0;
            overflow: hidden;
            object-fit: cover;
          }

          .tranin-courses_details {
            display: flex;
            flex-direction: column;
            padding: 16px 24px 32px;
            border-radius: 0 0 14px 14px;
            height: 160px;

            .train-course_name-group {
              display: flex;
              justify-content: space-between;

              .train-course__name {
                height: 40px;
                margin-right: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 1.8rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                visibility: visible;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &:hover {
                  cursor: pointer;
                }
              }

              svg {
                font-size: 2rem;
              }
            }

            .train-course__bottom {
              display: flex;

              .left-group {
                flex: 7;
                // display: flex;
                flex-direction: column;

                .train-course__price {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 1.8rem;
                  margin-top: 16px;
                  margin-bottom: 8px;
                }

                .train-course__location {
                  font-weight: 500;
                  font-size: 1.4rem;
                  line-height: 1.8rem;
                  margin-top: 4px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  visibility: visible;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin-right: 8px;

                  svg {
                    color: #25aae1;
                  }

                  .heart-icon {
                    margin-left: 12px;
                  }
                }
              }

              .date-group {
                flex: 3;
                padding-top: 16px;

                .date-month {
                  display: block;
                  font-size: 7px;
                  text-transform: uppercase;
                  border-radius: 2px 2px 0 0;
                  background: #eb1212;
                  color: #fff;
                  font-weight: 700;
                  height: 14px;
                  line-height: 15px;
                  text-align: center;
                }

                .date-detail {
                  border: 1px solid #ccc;

                  .date-num {
                    font-size: 2rem;
                    height: 2.6rem;
                    line-height: 2.8rem;
                    color: #666;
                    text-align: center;
                  }

                  .date-day {
                    font-size: 7px;
                    height: 1.4rem;
                    line-height: 1.4rem;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .pagination {
        margin-top: 24px;
        text-align: center;
      }
    }
  }
}