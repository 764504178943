.document-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .document-group {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .wrapper {
      display: flex;
      width: 100%;
      border: 2px solid  #25457C;
      border-radius: 14px;

      .content-left {
        padding: 16px 30px;
        display: flex;
        align-items: center;
        width: 70%;
        gap: 32px;

        .type {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }

        .des-title {
          display: flex;
          flex-direction: column;
          // gap: 4px;
          width: 100%;

          .title {
            font-weight: 500;
            font-size: 20px;
            padding-bottom: 8px;
            color: #df5327;
          }

          span {
            width: 100%;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #496189;
            margin-bottom: 20px;
          }

          .list-function {
            display: flex;
            gap: 36px;
            padding-bottom: 16px;
            .time {
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }

      .content-right {
        width: 50%;
        display: flex;
        gap: 20px;
        padding: 10px 20px;
        justify-content: right;
        font-size: 15px;

        .right {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: center;
          // align-items: center;

          img {
            // width: 15px;
            // height: 15px;
            cursor: pointer;
          }
        }

        .right2 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          // align-items: center;
          cursor: pointer;
          color:  #25457C;

          img {
            // width: 15px;
            // height: 15px;
          }
        }

        .detail-view {
          cursor: pointer;
          display: flex;
          color: red;
        }
      }
    }
  }
}
