.container-wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
.primary-color {
    color: #25457C
}
:root {
  --primary-color: #25457C;
}
.title-spacing {
    padding: 60px 0 10px 0
}
.custom-slider .slick-dots li button:before {
    font-size: 12px;
  }

  .custom-slider .slick-dots li {
    margin: 0 0px;
  }

  .custom-slider .slick-dots li.slick-active button:before {
    color: #25457C;
  }
  .custom-slider .slick-dots li button:before {
    font-size: 12px;
    color: #989898;
    transition: color 0.3s ease;
  }

  .custom-slider .slick-dots li button:hover:before {
    color: #eee;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
