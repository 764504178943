.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);

  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-login {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 5%;

      .content-right {
        height: auto;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 18px;

        .title {
          display: block;
          font-size: 4rem;
          line-height: 2.8rem;
          color: black;
          text-align: center;
          padding-bottom: 30px;
        }

        .login-form {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          align-items: center;
          .form-item-input {
            width: 400px;
            height: 50px;
            border: 1px solid  #25457C;
            border-radius: 10px;
            padding-left: 24px;
            display: flex;
            align-items: center;
            column-gap: 8px;

            input {
              height: 100%;
              width: 100%;
              font-size: 16px;
              line-height: 3rem;
              outline: none;
              border: 1px solid transparent;
              background-color: transparent;
              color: black;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
              -webkit-text-fill-color: black !important;
            }
          }

          .password-action {
            display: flex;
            justify-content: space-between;
            width: 400px;

            .remember-password {
              display: flex;
              align-items: center;
              color: black;
              column-gap: 6px;

              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                border: 2px solid  #25457C;
                border-radius: 8px;
                outline: none;
              }

              span {
                font-size: 14px;
                line-height: 17px;
              }
            }

            .forgot-password {
              display: flex;
              align-items: center;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #fb2525;

              p {
                width: 116px;
              }
            }
          }

          .password-or {
            display: flex;
            justify-content: flex-end;
            padding-right: 42px;
            color: black;
          }
        }
      }
    }

    .content-login-social {
      padding-top: 48px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100px;
      gap: 15px;

      .content-login-social-detail {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        img {
          cursor: pointer;
        }
      }

      .content-login-register {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        p {
          color: black;
        }

        .register {
          color:  #25457C;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
