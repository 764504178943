.ticket-recipient {
  width: 100%;

  .ticket-top {
    border: 1px solid #ababab;
    padding: 24px;
    box-shadow: 5px 5px 10px 0px #aeaec04d;
    border-radius: 24px;
  }

  .recipient-group {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 600;
      font-size: 1.8rem;
      font-weight: 2.8rem;
      color:  #25457C;
      border-bottom: 3px solid #ccc;
      padding-bottom: 16px;
      text-align: center;
    }

    .recipient-detail {
      color: #838383;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin-top: 12px;

      .form-group {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.2rem;

        .left-detail {
          display: flex;
          column-gap: 10px;
          align-items: center;

          svg {
            font-size: 2rem;
            width: 20px;
          }
        }
      }
    }
  }

  .payment-method {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    row-gap: 12px;
    margin-left: 4%;
    .title {
      color: #000000;
      font-weight: 600;
      font-size: 1.6rem;
      font-weight: 2.8rem;
      padding-top: 16px;
      list-style: disc;
    }

    .payment-detail {
      text-align: center;
      span {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.2rem;
      }
    }
  }

  .booking-detail {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    row-gap: 12px;
    margin-bottom: 24px;
    margin-left: 4%;

    .title {
      color: #000000;
      font-weight: 600;
      font-size: 1.6rem;
      font-weight: 2.8rem;
      padding-top: 24px;
      list-style: disc;
    }

    .form-group {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.2rem;
      border-bottom: 1px dashed #ccc;
      padding-bottom: 8px;

      .left-detail {
        font-size: 14px;
        flex: 7;
        display: flex;
        align-items: center;
      }

      .right-detail {
        font-size: 14px;
        flex: 3;
        display: flex;
        color: #000000;
        justify-content: flex-end;
      }
    }

    .form-detail {
      display: flex;

      .left-detail {
        flex: 7;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.2rem;
      }

      .right-detail {
        flex: 3;
        display: flex;
        justify-content: flex-end;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.2rem;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 0 24px;

    label {
      color: #000000;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.2rem;
    }

    span {
      font-size: 2rem;
      color: #fb2525;
      font-weight: 600;
      line-height: 2.2rem;
    }
  }

  .action-btn {
    width: 100%;
    height: 40px;
    margin-top: 24px;

    button {
      width: 100%;
      height: 100%;
      background-color: #39b54a;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.2rem;
      transition: 0.3s;
      border-radius: 14px;
      box-shadow: 5px 5px 10px 0px #aeaec04d;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
