.fair-detail-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  // row-gap: 22px;

  h3 {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  .social-btn {
    display: flex;
    column-gap: 16px;

    button {
      display: flex;
      align-items: center;
      column-gap: 8px;
      border-radius: 20px;
      padding: 8px 16px;

      &:hover {
        cursor: pointer;
      }

      svg {
        font-size: 2rem;
      }
    }

    .facebook-btn {
      background: #1877f2;
      color: var(--white);
    }

    .twitter-btn {
      background: #1d9bf0;
      color: var(--white);
    }

    .tiktok-btn {
      background: #ffffff;
      border: 1px solid #d9e1ec;
    }
  }

  .fair-status {
    margin-top: 16px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 8px;

    .dot-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #369313;
    }
  }

  .time-group {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 8px;

    svg {
      font-size: 1.6rem;
    }
  }

  .time2 {
    margin-bottom: 16px;
  }

  .fair-register {
    display: flex;
    align-items: center;
    column-gap: 12px;

    button {
      background:  #25457C;
      border-radius: 12px;
      color: var(--white);
      padding: 12px 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .fair-address-group {
    display: flex;
    column-gap: 100px;
    justify-content: center;
    margin-top: 96px;

    .fair-address {
      width: 500px;
      height: 200px;
      background:  #25457C;
      position: relative;
      display: flex;
      justify-content: center;

      .icon-address {
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translateX(-50%) translateY(20%);
        right: auto;
        width: 150px;
        height: 150px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(189, 189, 189, 1);
      }

      .text-group {
        margin-top: 100px;
        text-align: center;
        color: var(--white);

        h4 {
          font-weight: 500;
          font-size: 3rem;
          color: var(--white);
          line-height: 3.6rem;
        }

        span {
          font-weight: 500;
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }

    .fair-time {
      width: 500px;
      height: 200px;
      background: #f47319;
      position: relative;
      display: flex;
      justify-content: center;

      .icon-address {
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translateX(-50%) translateY(20%);
        right: auto;
        width: 150px;
        height: 150px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(189, 189, 189, 1);
      }

      .text-group {
        margin-top: 100px;
        text-align: center;
        color: var(--white);

        h4 {
          font-weight: 500;
          font-size: 3rem;
          color: var(--white);
          line-height: 3.6rem;
        }

        span {
          font-weight: 500;
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  .fair-content {
    padding: 24px 260px;
  }
}
