.wrapper {
  width: 100%;
  display: flex;
  min-height: 100%;
  border-top: 2px solid rgba(216, 210, 210, 0.7);
  background: #f0f0f3;
  margin-top: 100px;
  // background-image: url("../../../../assets/images/home/header/footer.jpg");
  .wrapper-bannber {
    width: 100%;
    position: relative;
    height: 100%;
  }

  .inner {
    width: 100%;
    display: flex;
    padding: 20px 140px;
    justify-content: space-between;

    .container {
      display: flex;
      width: 100%;

      .contents {
        display: flex;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        padding-top: 40px;

        .content-logo {
          width: 345px;
          height: 100px;
        }

        .content-des {
          font-size: 16px;
          padding-top: 10px;
          padding-right: 35px;
          color: #ffff;
        }
      }

      .form-register {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-register-detail {
          align-items: center;
          width: 460px;
          height: 400px;
          background-color: rgba(217, 217, 217, 0.4);
          border-radius: 10px;

          .form-register-detail-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .form-input {
              display: flex;
              flex-direction: column;
              padding: 10px;
              gap: 20px;
              width: 100%;

              input {
                height: 40px;
                border-radius: 10px;
                border: none;
                padding: 10px;
                outline: none;
              }
            }
          }
        }
      }
    }

    .col-group {
      display: flex;
      flex-direction: column;
      max-width: 470px;

      h5 {
        font-size: 1.6rem;
        font-weight: 600;
      }

      span {
        font-size: 1.6rem;
        font-weight: 500;
      }

      p {
        margin-bottom: 4px;
      }

      .row-group {
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 8px;

        .phone-icon {
          font-size: 1.8rem;
        }

        .mail-icon {
          font-size: 1.8rem;
        }

        .mail-icons {
          font-size: 1.9rem;
          width: 18px;
        }

        .mail-iconss {
          font-size: 1.8rem;
        }

        .social-icon {
          width: 40px;
          height: 40px;
        }

        .payment-icon {
          width: 180px;
        }

        .right-action {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .row-groups {
        height: 59px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 8px;

        .phone-icon {
          color: cadetblue;
          font-size: 1.8rem;
        }

        .mail-icon {
          color: crimson;
          font-size: 1.8rem;
        }

        .social-icon {
          width: 40px;
          height: 40px;
        }

        .payment-icon {
          width: 260px;
        }

        .right-action {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .delivery {
        margin-top: 40px;
      }

      span {
        font-weight: 400;
        color: #828282;
      }

      color: #828282;
      font-weight: 400;
    }
  }
}
