.card-company-wrapper {
  width: 23%;
  // height: 440px;
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);

  // &:hover {
  //   cursor: pointer;

  //   .logo-title {
  //     position: absolute;
  //     top: 10%;
  //     margin-right: auto;
  //     margin-left: auto;
  //     right: 0;
  //     left: 0;
  //     width: 90%;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     z-index: 100;
  //     transition: ease 0.7s;
  //   }

  //   .company-detail {
  //     display: flex;
  //     flex-direction: column;
  //     position: absolute;
  //     margin-right: auto;
  //     margin-left: auto;
  //     right: 0;
  //     left: 0;
  //     top: 45%;
  //     padding: 5%;
  //     transition: ease 0.7s;
  //     color: #ffffff;
  //     opacity: 1;

  //     div {
  //       font-size: 16px;
  //     }
  //   }

  //   .see-detail {
  //     position: absolute;
  //     bottom: 30px;
  //     right: 20px;
  //     opacity: 1;

  //     button {
  //       background:  #25457C;
  //       border-radius: 30px;
  //       padding: 8px 16px;
  //       color: #ffffff;
  //       transition: 0.4s;
  //       font-size: 1.6rem;

  //       &:hover {
  //         cursor: pointer;
  //         background: #ffffff;
  //         color:  #25457C;
  //       }
  //     }
  //   }
  // }

  // &::before {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   border-radius: 10px;
  //   background-color: rgba(0, 0, 0, 0.3);
  // }

  .logo-title {
    // position: absolute;
    // top: 20%;
    // margin-right: auto;
    // margin-left: auto;
    // right: 0;
    // left: 0;
    // width: 90%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // z-index: 100;

    .image-group {
      // width: 70%;
      // display: flex;
      // justify-content: center;

      img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .wrap-text {
    padding: 16px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 290px;
    .see-detail {
      margin-top: auto;
      // position: absolute;
      // bottom: 10px;
      // right: 0px;
      // opacity: 0;
      // transition: ease 0.7s;

      button {
        border: 1px solid transparent;
        background:  #25457C;
        border-radius: 30px;
        padding: 8px 24px;
        color: #ffffff;
        transition: 0.4s;
        font-size: 1.4rem;

        &:hover {
          cursor: pointer;
          background: #ffffff;
          color:  #25457C;
          border: 1px solid  #25457C;
        }
      }
    }

    .title-group {
      margin-top: 4px;
      font-size: 20px;
      padding-bottom: 8px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      min-height: 70px;
    }

    .company-detail {
      margin-right: auto;
      margin-left: auto;
      .address1 {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        visibility: visible;
      }
      // right: 0;
      // left: 0;
      // top: 50%;
      // padding: 5%;
      font-size: 16px;

      .email {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        // min-height: 70px;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .card-company-wrapper .wrap-text {
    padding: 16px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 300px;
  }
}
