.news-details-wrapper {
  width: 100%;

  .inner {
    padding: 20px 72px;
    display: flex;
    column-gap: 5%;

    .social-network-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 24px;
      font-size: 2.6rem;
      color:  #25457C;
    }

    .news-content {
      flex: 6.5;

      h1 {
        font-weight: 700;
        font-size: 2.6rem;
        line-height: 3.4rem;
      }

      .news-category {
        background: #f6feff;
        opacity: 0.4;
        border-radius: 4px;
        width: fit-content;
        padding: 4px 16px;
        color:  #25457C;
        margin-top: 12px;
      }
      .in-progress {
        padding: 8px 16px;
        background: #e7fcff;
        border-radius: 12px;
        font-size: 14px;
        border: 1px solid #ddd;
      }

      .image-wrapper {
        margin-top: 12px;
        width: 100%;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .desc {
        margin-top: 12px;
      }

      .news-content {
        margin-top: 12px;
      }

      .comment-group {
        margin-top: 24px;

        button {
          background:  #25457C;
          border-radius: 4px;
          padding: 8px 24px;
          color: #ffffff;
          margin-top: 12px;
          transition: 0.3s;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }

    .most-watch {
      display: flex;
      flex-direction: column;
      flex: 2.5;

      h2 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
      }

      .list-news {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 32px;

        .news-item {
          display: flex;
          flex-direction: column;
          border-radius: 3px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
          display: flex;
          flex-direction: column;
          padding: 16px;
          .news-title {
            &:hover {
              cursor: pointer;
            }
          }

          .bottom {
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: #072d4b;
            opacity: 0.4;
          }
        }
      }
    }
  }
}
