.wrapper#activities {
  width: 100%;
  height: auto;
  padding: 40px 0;

  .inner {
    width: 100%;
    height: auto;

    .top-main {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 140px;

      .list-activities {
        width: 100%;
        display: flex;
        border-radius: 14px;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
        padding: 16px 40px;
        display: flex;
        justify-content: space-between;

        .activities-item {
          cursor: pointer;
        }

        .activities-item.advise {
          color:  #25457C;
        }

        .activities-item.cooperate {
          color:  #25457C;
        }

        .activities-item.participate {
          color:  #25457C;
        }

        .activities-item.courses {
          color:  #25457C;
        }

        li {
          display: flex;
          column-gap: 12px;
          align-items: center;
          font-weight: 600;
          font-size: 2rem;
          line-height: 2.8rem;
          color: #989898
        }
      }
    }

    .content-main {
      width: 100%;
      padding: 0 140px;
    }
  }
}