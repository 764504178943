.select-container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 100%;
    padding       : 40px 140px;

    .select-event-document {
        display        : flex;
        justify-content: center;
        column-gap     : 100px;
        width          : 80%;

        p {
            font-size: 2rem;
        }
    }

    .item-select {
        display        : flex;
        justify-content: space-around;
        flex-wrap      : wrap;
        width          : 100%;
        gap            : 30px;
        margin-top     : 30px;

        .item {
            display        : flex;
            justify-content: flex-start;
            border-radius  : 10px;
            box-shadow     : rgba(0, 0, 0, 0.24) 0px 3px 8px;
            height         : 190px;
            width          : 48%;

            .item-img {
                width: 40%;

                img {
                    width                    : 100%;
                    height                   : 100%;
                    object-fit               : cover;
                    border-top-left-radius   : 10px;
                    border-bottom-left-radius: 10px;

                }
            }

            .item-content {
                display        : flex;
                flex-direction : column;
                justify-content: center;
                align-items    : flex-start;
                width          : 60%;
                font-size      : 1.5rem;
                padding        : 20px;

                p {
                    span {
                        margin-right: 5px;
                    }
                }

                .text-name {
                    font-size    : 20px;
                    color        : #222121;
                    margin-bottom: 10px;
                }

                .text-ic {
                    color        : #838383;
                    font-size    : 14px;
                    margin-bottom: 5px;
                }

                button {
                    margin-top   : 10px;
                    background   : #25457C;
                    color        : #ffffff;
                    border-radius: 25px;
                }
            }
        }
    }

}