.header-wrapper {
  width: 100%;
  z-index: 10;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    background: #ffffff;
    backdrop-filter: blur(15px);
    border-bottom: 1px solid #ddd;

    a {
      display: flex;
      align-items: center;

      img {
        width: 80px;
      }
    }

    .to-business {
      background: #25aae1;
      border-radius: 18px;
      padding: 8px 24px;
      color: #ffffff;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .nav-group {
      display: flex;
      column-gap: 38px;
      width: 100%;

      & > ul {
        width: 100%;
        border: none;
        justify-content: center;
        align-items: center;
      }
      // height: 100px;

      // &::after {
      //     content: '';
      //     position: absolute;
      //     border-right: 2px solid #ffffff;
      //     width: 2px;
      //     height: 50px;
      //     top: 50%;
      //     right: -12px;
      //     transform: translateY(-50%);
      // }

      .nav-item {
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: #129ff1;
        position: relative;
        overflow: hidden;
        height: 100%;

        &:hover {
          color: #3e4345;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 0%;
          height: 0.3em;
          background: #25aae1;
          border-radius: 20px;
          opacity: 0;
          transition: opacity 300ms, transform 500ms;
        }

        &::after {
          opacity: 1;
          transform: translate3d(-100%, 0, 0);
        }

        &:hover::after,
        &:focus::after {
          transform: translate3d(0, 0, 0);
        }
      }
    }
    .admin-profile {
      display: flex;
      column-gap: 10px;
      align-items: center;


      .language {
        display: flex;
        margin-bottom: 6px;
      }

      .action-btn {
        display: flex;
        .btn-login {
          min-width: 100px;
          height: 40px;
          background-color: #25457C;
          color: #ffffff;
          border-radius: 8px;
          transition: 0.3s;
          // border: 1px solid #25aae1;

          &:hover {
            cursor: pointer;
            background-color: #ffffff;
            color:  #25457C;
            border: 1px solid  #25457C;
          }
        }

        .btn-register {
          min-width: 100px;
          height: 40px;
          background-color: #25457C;
          color: #ffffff;
          border-radius: 8px;
          transition: 0.3s;
          //   border: 1px solid #25aae1;
          margin-left: 8px;

          &:hover {
            cursor: pointer;
            background-color: #ffffff;
            color:  #25457C;
            border: 1px solid  #25457C;
          }
        }
      }

      .cart {
        width: 24px;
        height: 24px;
        background-image: url("~/src/assets/images/User/shoppingcart.svg");
        background-size: cover;
        position: relative;
      }

      .active-cart::after {
        content: "";
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        top: 0;
        right: 0;
        border-radius: 50%;
        background-color: #ff0000;
      }

      .language-group {
        display: flex;
        column-gap: 2px;
        align-items: center;
        margin-bottom: 4px;

        .vi-language {
          width: 20px;
          height: 20px;
          background-image: url("~/src/assets/images/User/vietnam.svg");
          background-size: cover;
          background-repeat: no-repeat;
        }

        svg {
          font-size: 1.2rem;
          margin-top: -4px;
        }
      }
    }
  }

  .language-result {
    background-color: #ffffff;
    min-width: 32px;
    min-height: 40px;
    margin-left: -1px;
    border: 1px solid #4285f4;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;

    .vi {
      margin-left: 2px;
      width: 20px;
      height: 18px;
      background-image: url("~/src/assets/images/User/vietnam.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }

    .en {
      width: 18px;
      height: 15px;
      background-image: url("~/src/assets/images/User/english.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after {
  border-bottom: unset !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after {
  border-bottom: unset !important;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 0px;
  bottom: 0;
  left: 0px;
  border-bottom: 0px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: red !important;
}
